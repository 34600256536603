export interface InsightsRequest {
  urls: string[];
  time_scope: TimeFilter;
}

export enum TimeFilter {
  Alltime = 'Alltime',
  Today = 'Today',
  LastHour = 'LastHour'
}

export interface InsightsResponse {
  storyId: string;
  has_paywall: boolean;
  page_location: string;
  page_views: number;
  pageviews_behind_paywall: number;
  time_spent: number;
  time_spent_formatted: string;
  time_spent_behind_paywall: string;
  time_spent_behind_paywall_formatted: string;
  paywall_click: number;
  time_scope: TimeFilter;
}

const isIpInternal = function (): Promise<boolean> {
  const url = '/api/insights/isipinternal';

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: new Headers(),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.text();
    })
    .then(text => text == 'true' ? true : false);
};

const getInsights = function (request: InsightsRequest, shouldGetFromCache: boolean): Promise<Array<InsightsResponse>> {
  const url = `/api/insights/getinsights?shouldGetFromCache=${shouldGetFromCache}`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request)

  }).then(response => response.json());
};

export default {
  isIpInternal,
  getInsights
};
