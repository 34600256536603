import IComponent from './IComponent';
import InsightsApi from '../apis/insights-api';
import '../elements/insights-toggle-bar';

const register = async function (): Promise<void> {
  const ipInternal = await InsightsApi.isIpInternal()
    .catch(() => false);

  if (!ipInternal)
    return;

  renderInsightsBar();
};

const renderInsightsBar = () => {
  const insightsToggleBar = document.createElement('insights-toggle-bar');
  document.body.prepend(insightsToggleBar);
};

const insightsComponent: IComponent = {
  name: 'Insights',
  register: register
};

export default insightsComponent;
