import InsightsApi, { InsightsResponse, TimeFilter } from '../apis/insights-api';

export class HTMLInsightsBarElement extends HTMLElement {
  public refreshButton: HTMLButtonElement;
  articleUrl: string;
  timeFilterButtons: NodeListOf<HTMLButtonElement>;
  selectedTimeFilter: TimeFilter = TimeFilter.Alltime;

  constructor() {
    super();
  }

  connectedCallback() {
    // create template
    const template = document.getElementById('insights-bar') as HTMLTemplateElement;
    const templateContent = template.content;
    this.prepend(templateContent.cloneNode(true));

    this.selectedTimeFilter = this.getTimeFilter();

    this.registerEventListeners();
    this.loadData();
  }

  registerEventListeners() {
    //Refresh button
    this.refreshButton = this.querySelector('[data-refresh]');
    if (this.refreshButton) {
      this.refreshButton.addEventListener('click', this.handleRefreshClicked.bind(this));
    }

    //Timefilter buttons
    this.timeFilterButtons = this.querySelectorAll('[data-time-scope]');
    this.timeFilterButtons.forEach(button => {
      button.addEventListener('click', this.handleTimeFilterClicked.bind(this));
    });
  }

  handleRefreshClicked(e: MouseEvent) {
    e.preventDefault();
    this.loadData();
  }

  handleTimeFilterClicked(e: MouseEvent) {
    e.preventDefault();
    const btn = e.target as HTMLElement;

    const timeFilter = TimeFilter[btn.dataset.timeScope as keyof typeof TimeFilter];
    this.selectedTimeFilter = timeFilter;

    this.loadData();
  }

  public async loadData() {
    this.toggleButtons();
    this.toggleLoader();
    //disable buttons while loading new data
    this.timeFilterButtons.forEach(button => button.disabled = true);

    const response = await InsightsApi.getInsights({
      urls: [this.articleUrl],
      time_scope: this.selectedTimeFilter
    }, false);
    this.updateData(response[0]);

    this.toggleLoader();
    //enable buttons when data has been loaded
    this.timeFilterButtons.forEach(button => button.disabled = false);
  }

  updateData(data: InsightsResponse) {
    if (!data) return;

    this.querySelector('.views').innerHTML = data.page_views.toString();
    this.querySelector('.time_spent_formatted').innerHTML = data.time_spent_formatted.toString();

    const viewsBehindPaywall = this.querySelector('.views-bahind-paywall') as HTMLElement;
    const payWallClicks = this.querySelector('.paywall-clicks') as HTMLElement;

    if (data.has_paywall) {
      viewsBehindPaywall.innerHTML = data.pageviews_behind_paywall.toString();
      payWallClicks.innerHTML = data.paywall_click.toString();
    } else {
      payWallClicks.parentElement.style.display = 'none';
      viewsBehindPaywall.parentElement.style.display = 'none';
    }
  }

  toggleButtons() {
    const selectedTimeFilterButton = this.querySelector(`[data-time-scope=${this.selectedTimeFilter}]`) as HTMLButtonElement;
    const buttonGroup = selectedTimeFilterButton.parentElement.querySelectorAll('button') as NodeListOf<HTMLElement>;

    buttonGroup.forEach(btn => btn.classList.remove('insights-bar__button--active'));
    selectedTimeFilterButton.classList.add('insights-bar__button--active');
  }

  toggleLoader() {
    if (!this.refreshButton) return;
    const icon = this.refreshButton.querySelector('.refresh') as HTMLElement;
    const loader = this.refreshButton.querySelector('.spinner') as HTMLElement;

    if (icon && icon.style.display === 'block') {
      icon.style.display = 'none';
    } else {
      icon.style.display = 'block';
    }

    if (loader && loader.style.display === 'block') {
      loader.style.display = 'none';
    } else {
      loader.style.display = 'block';
    }
  }

  /**
 * Returns localstorage key mapped to a TimeFilter enum value. If invalid: defaults to TimeFilter.Always.
 * @returns {TimeFilter}
 */
  getTimeFilter(): TimeFilter {
    const insightsTimeScope: string = localStorage.getItem('insights-time-scope');
    const timeFilter: TimeFilter = TimeFilter[insightsTimeScope as keyof typeof TimeFilter];

    // If insightsTimeScope cannot be mapped to a valid TimeFilter enum, it will be 'undefined'.
    // This is also the case with 'null'.eg.if no value is set for localstorages 'insights-time-scope' key.
    return timeFilter == undefined ? TimeFilter.Alltime : timeFilter;
  }
}

customElements.define('insights-bar', HTMLInsightsBarElement);
